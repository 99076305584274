import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { AsesorService } from 'src/app/service/asesor.service';


@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

  constructor(
    public _asesorService: AsesorService,
    public router:Router
  ){}

  canActivate(){
     if( this._asesorService.estaLogueado() ){
      this.router.navigate(['/inicio'])
      return false;
    }else{
      return true;
    }
  }

  canActivateChild(){
    if( !this._asesorService.estaLogueado() ){
      this.router.navigate(['/login'])
      return false;
    }else{
      return true;
    }
  }

}
