export class asesorModelo {
  constructor(
    public asesor_id?:string,
    public identidad?:string,
    public nombre_completo?:string,
    public puesto?:string,
    public email?:string,
    public contrasena?:string,
    public telefono?:string,
    public imagen_profile?:string,
    public acercade?:string,
    public activo?:number,
  ){}
}
