import { Injectable } from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AsesorService } from './asesor.service';
import { catchError, filter, map } from 'rxjs/operators';
import {throwError} from 'rxjs';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private _asesorService:AsesorService,
    public _router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
      if(!this._asesorService.token_adm || !this._asesorService.asesor.asesor_id){
        var token: string     = '';
        var asesor_id:string  = '';
      }else{
        var token: string     = this._asesorService.token_adm;
        var asesor_id:string  = this._asesorService.asesor.asesor_id;
      }

      const cabezera = req.clone({
        setHeaders:
          {
            'token'       : token,
            'asesor_id'   : asesor_id,
            'usuario_id'  : '',
            'tipo_sesion' : 'asesor',

          }
      });


      return next.handle(cabezera).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if(event.body._error ==true && event.body._error_codigo=="1-1-1"){
              this._asesorService.logout();
            }
            return event;
          }
        })
      );

      //return next.handle(cabezera);
      /* .pipe(
      catchError(respuesta => {
        
        return throwError(respuesta);
      })); */

      /* return next.handle(cabezera).pipe(
        catchError(respuesta => {
          let titulo:string;
          let descripcion:string;

          if (respuesta instanceof HttpErrorResponse) {
            titulo="Identificador de sesión no valido";
            descripcion="No tiene autorización, por favor, inicie sesión nuevamente";
          } else {
            titulo="Identificador de sesión no valido.";
            descripcion="No tiene autorización, por favor, inicie sesión nuevamente.";
          }

          Swal.fire({
            imageUrl: 'assets/images/logo_mini.png',
            title: titulo ,
            text: descripcion,
            focusConfirm: true,
            confirmButtonText: 'Aceptar',
          });

          return throwError(respuesta);

        })
      ); */




      /* return next.handle(cabezera).pipe(
        catchError(respuesta => {
          let errorMessage;
          if (respuesta instanceof HttpErrorResponse) {
            alert('No hay error');
          } else {
            if(respuesta._error == true && respuesta._error_codigo == '1-1-1'){
              alert('hay error 1-1-1');
            }
            alert('hay error, pero no es 1-1-1');
          }
          return throwError(respuesta);
        })
      ) */


      /* .pipe(
        catchError(respuesta => {
          alert(respuesta._titulo);
          return throwError( respuesta );
        })
      ); */

  }

}
