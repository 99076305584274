import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { asesorModelo } from '../model/asesor.model';
import { AsesorService } from '../service/asesor.service';


/* import { SocialAuthService, SocialUser } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login"; */

import Swal from 'sweetalert2';
import { ICON_LOGIN } from '../config/iconos';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  forma: FormGroup;
  tipo:string="password";

  icon_login = ICON_LOGIN;

  /* user: SocialUser; */
  loggedIn: boolean;

  deshabilitarLogin:boolean=false;
  verGoogle:boolean=true;

  constructor(
    public _asesorService: AsesorService,
    public router: Router,
    /* private authService: SocialAuthService */
    ) {
        this.forma = new FormGroup({

          email: new FormControl( '', Validators.required ),
          contrasena: new FormControl( '', Validators.required )
        })
     }

  ngOnInit(): void {
    /* this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);

      if(user != null && this.forma.value.email!="" && this.forma.value.contrasena!=""){
        //this.forma.value.email=user.email;
        this.forma.setValue({
          email        : this.user.email,
          contrasena   : "xxxxxxxxxxx"
        });
        this.deshabilitarLogin=true;
        this.ingresar(true);
      }
    }); */
  }

  alternarInicioSesion(){
    if(this.verGoogle==true){
      this.verGoogle=false;
    }else{
      this.verGoogle=true;
    }
  }


  signInWithGoogle(): void {
    this.deshabilitarLogin=true;
    if(this.forma.value.email=="" || this.forma.value.contrasena==""){
      Swal.fire({
        imageUrl: 'assets/images/logo_mini.png',
        title: "Debe ingresar usuario y contraseña" ,
        text: "Es necesario completar todos los campos",
        focusConfirm: true,
        confirmButtonText: 'Aceptar',
      });
      this.deshabilitarLogin=false;
      return;
    }

    /* if(this.forma.value.email!="" && this.forma.value.contrasena!=""){
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    } */
  }

  /* signOut(): void {
    this.authService.signOut();
  } */


  verContasena(){
    if(this.tipo=="text"){
      this.tipo="password";
      return
    }
    if(this.tipo=="password"){
      this.tipo="text";
      return;
    }
  }

  ingresar(conGoogle:boolean){
    this.deshabilitarLogin=true;
    if (!this.forma.valid){ // ó this.forma.invalid
      /* this.titulo_error="Debe ingresar usuario y contraseña";
      this.descripcion_error="Es necesario completar todos los campos"; */
      /* $("#info-alert-modal").modal(); */
      Swal.fire({
        imageUrl: 'assets/images/logo_mini.png',
        title: "Debe ingresar usuario y contraseña" ,
        text: "Es necesario completar todos los campos"
      });
      this.deshabilitarLogin=false;
     return;
    }

    if(this.forma.value.email=="" || this.forma.value.contrasena==""){
      Swal.fire({
        imageUrl: 'assets/images/logo_mini.png',
        title: "Debe ingresar usuario y contraseña" ,
        text: "Es necesario completar todos los campos",
        focusConfirm: true,
        confirmButtonText: 'Aceptar',
      });
      this.deshabilitarLogin=false;
      return;
    }



    let asesor = new asesorModelo(
      null,null,null,null,
      this.forma.value.email,
      this.forma.value.contrasena
    )

    
    this._asesorService.login(asesor,conGoogle)
    .subscribe((resp:any)=>{
      
      if(resp._error==false){
        this._asesorService.guardar_storage(resp._token,resp._contenido[0]);
        /* if(conGoogle){
          this.authService.signOut();
        } */

        this.router.navigate(['inicio']);
      }else{
        Swal.fire({
          imageUrl: 'assets/images/logo_mini.png',
          title: resp._titulo ,
          text: resp._descripcion
        });
        this.deshabilitarLogin=false;
      }
    },error => {
      Swal.fire({
        imageUrl: 'assets/images/logo_mini.png',
        title: 'Error de inicio de sesion' ,
        text: 'Se produjo un error al querer iniciar sesion',
        focusConfirm: true,
        confirmButtonText: 'Aceptar',
      });
      this.deshabilitarLogin=false;
    })


   // this.router.navigate(['/inicio']);  this.router.navigate(['inicio']);
  }

}
