import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vista-rapida',
  templateUrl: './vista-rapida.component.html',
  styleUrls: ['./vista-rapida.component.css']
})
export class VistaRapidaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
