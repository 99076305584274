<!-- <div class="auth-fluid" style="background-image: url(assets/images/background.jpg);"> -->
  <div class="auth-fluid">
    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
        <div class="align-items-center d-flex h-100">
            <div class="card-body">

                <!-- Logo -->
                <div class="text-center">
                  <a href="index.html">
                    <span><img src="assets/images/logo.png" alt="" height="80"></span>
                </a>
                <p></p>
                <p></p>
                <h4 class="mt-0">Iniciar sesión</h4>
                <div class="mb-4" [hidden]="verGoogle==true"></div>
                <p class="text-muted mb-4" [hidden]="verGoogle==false">Ingrese su dirección de correo electrónico y contraseña para acceder a la cuenta.</p>
                </div>


                <!-- title-->



                <!-- form -->
                <div class="pb-3" [hidden]="verGoogle==true">
                  <div class="text-center text-muted pb-4"> Iniciar sesión autenticando con tu correo CDE MIPYME Región Lempa </div>
                  <button [disabled]="deshabilitarLogin" type="button" class="btn btn-primary btn-block" (click)="signInWithGoogle()">
                    <span *ngIf="deshabilitarLogin==true" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"> </span>
                    <i class="mdi mdi-login"></i> Iniciar sesión </button>
                </div>

                <form [hidden]="verGoogle==false" ngNativeValidate [formGroup]="forma" (ngSubmit)="ingresar(false)">
                    <div class="form-group">
                        <label for="email">Dirección de correo electrónico</label>
                        <input #email class="form-control" formControlName="email" type="email" id="email" name="email" required="" placeholder="Introduce tu correo electrónico">
                    </div>
                    <div class="form-group">
                        <!-- <a href="pages-recoverpw-2.html" class="text-muted float-right"><small>¿Olvidaste tu contraseña?</small></a> -->
                        <label for="contrasena">contraseña</label>
                        <div class="input-group">
                            <!-- <input required formControlName="contrasena" name="contrasena" [type]="tipo" class="form-control form-control-sm" id="rcontrasena"  placeholder="Password"> -->
                            <input  #contrasena class="form-control" formControlName="contrasena" [type]="tipo" required="" id="contrasena" name="contrasena" placeholder="Ingresa tu contraseña">
                            <div class="input-group-append " data-password="false" (click)="verContasena()">
                              <div class="input-group-text">
                                <span class="dripicons-preview"></span>
                              </div>
                            </div>
                          </div>

                    </div>

                    <div class="form-group mb-0 text-center">
                        <button [disabled]="deshabilitarLogin" type="submit" class="btn btn-primary btn-block"  > <!--type="button" onclick="location.href='/#admin'" -->
                          <span *ngIf="deshabilitarLogin==true" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"> </span>
                          Iniciar sesión <i [class]="icon_login"></i>
                        </button>
                    </div>

                </form>

                <!-- <div class="text-center pt-3"> <a href="javascript:void(0)" (click)="alternarInicioSesion()"> O iniciar sesión con
                  <span *ngIf="verGoogle==true"> cuenta correo CDE Región Lempa </span>
                  <span *ngIf="verGoogle==false"> credenciales de plataforma </span> </a>
                </div> -->
                <p></p>
            </div> <!-- end .card-body -->
        </div> <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->

    <!-- Auth fluid right content -->
    <!-- background: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), -->
    <div class="auth-fluid-right text-center"

        style="
        background-image : url('./assets/images/background.jpg');
        background-repeat: round;
        background-color:rgb(57, 151, 189);
        background-position: center;
        background-repeat: no-repeat;
          ">
        <div class="auth-user-testimonial">
            <h2 class="mb-3">Formando el futuro</h2>
            <p class="lead"><i class="mdi mdi-format-quote-open"></i> CDE - Formando parte del futuro. <i class="mdi mdi-format-quote-close"></i>
            </p>
        </div> <!-- end auth-user-testimonial-->
    </div>
    <!-- end Auth fluid right content -->
  </div>
  <!-- end auth-fluid-->
<!-- <pre>{{user | json}} +++----</pre> -->
