import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData } from '@angular/common';
import localeHN from '@angular/common/locales/es-HN';
registerLocaleData(localeHN);

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptorService } from './service/auth-interceptor.service';
import { VistaRapidaComponent } from './src/app/pages/modulo/asistenciatecnica/componentes/vista-rapida/vista-rapida.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    VistaRapidaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    BrowserAnimationsModule,

  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'es-HN',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
/* PROVIDERS {
  provide: 'SocialAuthServiceConfig',
  useValue: {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(
          '79411172583-mapcsjh503hcom0pvismbfh8d99duerp.apps.googleusercontent.com'
        ),
      },
    ],
  } as SocialAuthServiceConfig,
}

SocialLoginModule,

*/
