import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginGuardGuard } from './service/guards/login-guard.guard';


const routes: Routes = [
  {
    path: 'login', component: LoginComponent, canActivate: [LoginGuardGuard]
  },
  { path: "",
    redirectTo: "login",pathMatch: 'full'
  },
  { path: 'inicio', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),canActivateChild:[LoginGuardGuard]

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
