//######################################################################
//
//                  INICIO CONFIGURACION BANKEND Y FRONT END
//
//######################################################################



//  CONEXION SERVIDOR REMOTO API
let SVR                        = 'https://api.lempaonline.website';
let path                       = '/public/';
export const URL_SERVICIOS     =  SVR + path;

//  SERVIDOR LOCAL UPLOAD
let SVR_UPLOAD                 =  'https://api.lempaonline.website' ;
let FOLDER_UPLOAD              =  '/files/upload/' ;
export const URL_UPLOAD        =  SVR_UPLOAD + FOLDER_UPLOAD;

//  SERVIDOR LOCAL DOWNLOAD
let SVR_DOWNLOAD               =  'https://api.lempaonline.website' ;
let FOLDER_DOWNLOAD            =  '/files/download/' ;
export const URL_DOWNLOAD      =  SVR_DOWNLOAD + FOLDER_DOWNLOAD;

//  SERVIDOR IMAGENES
let SVR_IMAGES                 =  'https://api.lempaonline.website' ;
let FOLDER_IMAGES              =  '/files/download/assets/images/';
export const URL_IMAGES        =  SVR_IMAGES + FOLDER_IMAGES; 




// //  CONEXION SERVIDOR LOCAL API ESTE SI SIRVE
// let SVR                     = 'http://localhost';
// let path                    = '/slim_app/slim_app/public/';
// export const URL_SERVICIOS  =  SVR + path;

// //  SERVIDOR LOCAL UPLOAD
// let SVR_UPLOAD              =  'http://localhost' ;
// let FOLDER_UPLOAD           =  '/slim_app/slim_app/files/upload/';
// export const URL_UPLOAD     =  SVR_UPLOAD + FOLDER_UPLOAD;

// //  SERVIDOR LOCAL DOWNLOAD
// let SVR_DOWNLOAD            =  'http://localhost' ;
// let FOLDER_DOWNLOAD         =  '/slim_app/slim_app/files/download/';
// export const URL_DOWNLOAD   =  SVR_DOWNLOAD + FOLDER_DOWNLOAD;


// //  SERVIDOR IMAGENES
// let SVR_IMAGES               =  'http://localhost' ;
// let FOLDER_IMAGES            =  '/slim_app/slim_app/files/download/assets/images/';
// export const URL_IMAGES      =  SVR_IMAGES + FOLDER_IMAGES;



// //  CONEXION SERVIDOR LOCAL API
// let SVR                     = 'http://192.168.1.201:8928';
// let path                    = '/api-cde/api-v1-slim/slim_app/public/';
// export const URL_SERVICIOS  =  SVR + path;

// //  SERVIDOR LOCAL UPLOAD
// let SVR_UPLOAD              =  'http://192.168.1.201:8928' ;
// let FOLDER_UPLOAD           =  '/api-cde/api-v1-slim/slim_app/files/upload/';
// export const URL_UPLOAD     =  SVR_UPLOAD + FOLDER_UPLOAD;

// //  SERVIDOR LOCAL DOWNLOAD
// let SVR_DOWNLOAD            =  'http://192.168.1.201:8928' ;
// let FOLDER_DOWNLOAD         =  '/api-cde/api-v1-slim/slim_app/files/download/';
// export const URL_DOWNLOAD   =  SVR_DOWNLOAD + FOLDER_DOWNLOAD;


// //  SERVIDOR IMAGENES
// let SVR_IMAGES               =  'http://192.168.1.201:8928' ;
// let FOLDER_IMAGES            =  '/api-cde/api-v1-slim/slim_app/files/download/assets/images/';
// export const URL_IMAGES      =  SVR_IMAGES + FOLDER_IMAGES;



//######################################################################
//
//                  FIN CONFIGURACION BANKEND Y FRONT END
//
//######################################################################





































//let SVR   = 'http://api-cde.itgrahn.com:8080';
//let path  = '/';


//let SVR = 'http://181.115.96.9:8928';
/* let SVR = 'http://192.168.1.201:8928';
let path  = '/api-cde/api-v1-slim/slim_app/';

export const URL_APP                     =    'http://localhost:4201/';
export const URL_PROFILE_IMAGE_FOLDER    =    'assets/images/profile/';
export const URL_PROFILE_IMAGE_DEFAULT   =    'default.png';


//BACK END
export const URL_DOWNLOAD               =    SVR + path ;

export const URL_SERVICIOS               =    SVR + path + 'public/'; */

  //######################################################################
  //#
  //#                    USUARIO
  //#
  //######################################################################

  /* export const URL_ASESOR_IMAGE_FOLDER   =    'upload/assets/images/profile/';
  export const URL_ASESOR_IMAGE_DEFAULT   =    'default.png'; */

  //######################################################################
  //#
  //#                    ASESOR
  //#
  //######################################################################
/*
  export const URL_LOGIN                 =    'adm/login'; // LOGIN USUARIO
  export const URL_ASESOR                =    'adm/asesor';
  //export const URL_UPLOAD                =    'adm/upload'; // LOGIN USUARIO
  //export const URL_ASESOR_IMAGE_UPLOAD   =    SVR + path;
  export const URL_ASESOR_IMAGE_FOLDER   =    'upload/assets/images/profile/';
  export const URL_ASESOR_IMAGE_DEFAULT   =    'default.png'; */


  //######################################################################
  //#
  //#                    CURSO
  //#
  //######################################################################
/*
  //https://www.npmjs.com/package/ngx-dropzone
  export const URL_CURSO                       =    'cursos'; // OBTIENE LISTA DE CURSOS ?DESDE=
  export const URL_CURSO_AREA                  =    'cursos/area'; // OBTIENE LISTA DE AREASS DE CURSOS ?DESDE=
  export const URL_CURSO_MODULO                =    'cursos/modulo'; // OBTIENE LISTA DE CURSOS ?DESDE=
  //export const URL_CURSO_IMAGE_UPLOAD          =    SVR + path;
  export const URL_CURSO_IMAGE_SCRIPT          =    'upload.php';
  //export const URL_CURSO_IMAGE_FOLDER          =    'upload/assets/images/area/curso/';

  // MODULO
  export const URL_MODULO                       =    'modulos'; // OBTIENE LISTA DE CURSOS ?DESDE=

  // LECCIONES
  export const URL_LECCION                       =   'lecciones'; // OBTIENE LISTA DE CURSOS ?DESDE=

  // TEMA
  export const URL_TEMA                       =   'temas'; // OBTIENE LISTA DE CURSOS ?DESDE=
  export const URL_TEMA_ARCHIVOSOPORTE        =   'temas/archivosoporte'; // OBTIENE LISTA DE CURSOS ?DESDE=
  //export const URL_TEMA_IMAGE_UPLOAD          =   SVR + path;
  export const URL_TEMA_IMAGE_FOLDER          =   'upload/assets/images/area/curso/modulo/leccion/tema/imagen/';
  export const URL_TEMA_PDF_FOLDER            =   'upload/assets/docs/area/curso/modulo/leccion/tema/';
  export const URL_TEMA_ARCHIVOSOPORTE_FOLDER =   'upload/assets/docs/area/curso/modulo/leccion/tema/archivosoporte';


  //######################################################################
  //#
  //#                    WEBINAR
  //#
  //######################################################################
  export const URL_WEBINAR                       =    'webinar'; // OBTIENE LISTA DE CURSOS ?DESDE=
  //export const URL_WEBINAR_IMAGE_UPLOAD          =    SVR + path;
  export const URL_WEBINAR_IMAGE_SCRIPT          =    'upload.php';
  export const URL_WEBINAR_IMAGE_FOLDER          =    'upload/assets/images/webinar/';

  //######################################################################
  //#
  //#                    USUARIOS
  //#
  //######################################################################

  export const URL_USUARIO                       =    'usr/'; // LOGIN USUARIO
  export const URL_PROSPECTOS                    =    'prospectos'; // LOGIN USUARIO

 */
