import { Injectable } from '@angular/core';
import { asesorModelo } from '../model/asesor.model';
import { URL_UPLOAD,URL_SERVICIOS } from '../config/config';

import { HttpClient } from '@angular/common/http'
import {map} from 'rxjs/operators'

import { Router } from '@angular/router';
/* import { SocialAuthService } from 'angularx-social-login'; */


@Injectable({
  providedIn: 'root'
})
export class AsesorService {

  public token_adm     :  string;
  public asesor        :  asesorModelo;

  constructor(
    public http:HttpClient,
    public router: Router,
    /* private authService: SocialAuthService */
  ) {
    this.cargarStorage();
  }

  // VALIDAMOS SI LA VARIABLE ESTA LOGUEADA
  estaLogueado(){
    if(this.token_adm.length>5 && this.asesor.asesor_id.length>0 && this.asesor.email.length>5){
      return true
    }else{
      localStorage.clear();
      return false;
    }
  }

  cargarStorage(){
    if(localStorage.getItem('token_adm') && localStorage.getItem('asesor')){
      this.token_adm  =   localStorage.getItem('token_adm');
      this.asesor     =   JSON.parse( localStorage.getItem('asesor')) ;
    }else{
      this.token_adm  =  '';
      this.asesor     =  null;
    }
  }

  guardar_storage(token_adm:string, asesor:asesorModelo){
    this.token_adm =  token_adm;
    this.asesor    =  asesor;

    localStorage.setItem('token_adm',token_adm);
    localStorage.setItem('asesor',JSON.stringify(asesor));
  }

  login(asesor: asesorModelo,conGoogle:boolean){
    let url   =  URL_SERVICIOS + 'adm/login' + "?congoogle=" + conGoogle;
    
    return this.http.post(url,asesor)
    /* .pipe(map((resp:any) =>{
      this.guardar_storage(resp._token,resp._contenido[0]);
      
    })) */
  }

  logout(){
    /* this.authService.signOut(); */
    this.token_adm='';
    localStorage.clear();
    this.asesor = null;
    this.router.navigate(['/login']);
  }

  listarAsesores(activos:number=1){
    let url = URL_SERVICIOS + 'adm/asesores?token=' + this.token_adm + '&activo=' + activos + '&asesor_id=' + this.asesor.asesor_id;
    
    return this.http.get(url);
  }

  nuevoAsesor(asesor:asesorModelo){
    let url = URL_SERVICIOS + 'adm/asesores?token=' + this.token_adm + '&asesor_id=' + this.asesor.asesor_id;
    return this.http.post(url,asesor);
  }

  obtenerAsesor(asesor_id:string){
    let url = URL_SERVICIOS + 'adm/asesores/'+ asesor_id +'?token=' + this.token_adm + '&asesor_id=' + this.asesor.asesor_id;
    return this.http.get(url);
  }

  actualizarAsesor(asesor:asesorModelo){
    let url = URL_SERVICIOS + 'adm/asesores/'+ asesor.asesor_id +'?token=' + this.token_adm + '&asesor_id=' + this.asesor.asesor_id;;
    
    return this.http.put(url,asesor);
  }

  uploadFileToActivity(fileToUpload: File, token:string, tipo:string ){
    let url = URL_UPLOAD + 'upload.php'  + '?token=' + token + '&tipo=' + tipo  + '&asesor_id=' + this.asesor.asesor_id;

   const endpoint = url;
   const formData: FormData = new FormData();

   formData.append('file', fileToUpload, fileToUpload.name);
   return this.http.post(endpoint, formData)
     .pipe(map((resp:any) => {
       return resp;
      }))
  }

  cambiarContrasena(asesor_id:string,contrasena:string,ncontrasena:string,rncontrasena:string){
    let url = URL_SERVICIOS + '' + 'adm/asesores/' + asesor_id + '/cambiarcontrasena' + '?token=' + this.token_adm;
    return this.http.put(url,{contrasena,ncontrasena,rncontrasena});
  }

  listarCiudades(token:string, usuario_id:string){
    let url = URL_SERVICIOS + 'conf/listarciudades?token=' + token + '&usuario_id' + usuario_id;
    return this.http.get(url);
  }
}


